<template>
	<AddEditForm :headerTitle="$t('pages.agent.addAgent')" :onGoBack="onGoBack" :showDelete="false">
		<template v-slot:headerAddEdit>
			<button type="button" name="add-addAgent" class="btn btn-primary" @click="onSave">
				{{ $t('common.save') }}
			</button>
		</template>
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingAgent != null">
				<template v-slot:body>
					<CForm>
						<CInput :placeholder="$t('pages.agent.enterAgentName')" v-model="editingAgent.name" :is-valid="validator" required horizontal>
							<template #label>
								<div class="col-sm-3 col-form-label">
									{{ $t('common.name') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>

						<CInput :label="$t('common.phone')" :placeholder="$t('common.enterPhoneNumber')" v-model="editingAgent.phone"
								@keypress="$func.onlyNumbers" horizontal>
						</CInput>

						<CInput :label="$t('common.address')" :placeholder="$t('pages.agent.enterAddress')" v-model="editingAgent.address" horizontal></CInput>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('pages.agent.ownerName') }}</label>
							<div class="col-sm-9 input-group">
								<vue-single-select v-model="ownerModel"
												   :options="agentOwnerUserList"
												   :required="false"
												   option-label="userName"
												   :placeholder="$t('pages.agent.selectOwnerName')"
												   :max-results="50" style="width:100%"></vue-single-select>
							</div>
						</div>						

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('pages.agent.selectProvince') }}</label>
							<div class="col-sm-9 input-group">
								<select class="form-control" v-model="editingAgent.provinceId" @change="onChangeProvince($event)">
									<option selected="selected" value="">{{ $t('pages.agent.selectProvince') }}</option>
									<option v-for="(item) in listProvinces" v-bind:key="item.provinceId" v-bind:value="item.provinceId">
										{{item.name}}
									</option>
								</select>
							</div>
						</div>
						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('pages.agent.selectDistrict') }}</label>
							<div class="col-sm-9 input-group">
								<select class="form-control" v-model="editingAgent.districtId" @change="onChangeDistrict($event)">
									<option selected="selected" value="">{{ $t('pages.agent.selectDistrict') }}</option>
									<option v-for="(item) in listDistricts" v-bind:key="item.districtId" v-bind:value="item.districtId">
										{{item.name}}
									</option>
								</select>
							</div>
						</div>
						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('pages.agent.selectWard') }}</label>
							<div class="col-sm-9 input-group">
								<select class="form-control" v-model="editingAgent.wardId">
									<option selected="selected" value="">{{ $t('pages.agent.selectWard') }}</option>
									<option v-for="(item) in listWards" v-bind:key="item.wardId" v-bind:value="item.wardId">
										{{item.name}}
									</option>
								</select>
							</div>
						</div>

						<CInput :label="$t('common.subject')" :placeholder="$t('pages.agent.enterSubject')" v-model="editingAgent.subject" horizontal></CInput>
						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">
								{{ $t('common.description') }}
							</label>
							<div class="col-sm-9 input-group">
								<ckeditor v-model="editingAgent.description" :config="editorConfig"></ckeditor>
							</div>
						</div>
					</CForm>
				</template>
			</Accordion>
		</template>
	</AddEditForm>
</template>

<script>	
	import { mapGetters } from 'vuex'
    import { globalConfigs } from '@/global/index'
    import Accordion from '@/components/Accordion.vue'
	import AddEditForm from '@/components/AddEditForm.vue'
    import VueSingleSelect from "vue-single-select";

	export default {
		name: 'Tables',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				editorConfig: globalConfigs.CKEditorConfig,				
				ownerModel: null,
                managerModel: null
			}
		},
		computed: {
			...mapGetters('agent', ['allAgents', 'editingAgent']),
			...mapGetters('location', ['listProvinces', 'listDistricts', 'listWards']),
            ...mapGetters('account', ['agentOwnerUserList'])
		},
		components: {
            Accordion, AddEditForm, VueSingleSelect
		},
		methods: {
            onSave() {
                this.onSaveNewAgent();
            },
            onGoBack() {
                this.$router.push('/agents/list');
            },
			validator(val) {				
				return val ? val.length > 0 : false
            },			
			async onChangeProvince(event) {				
				await this.$store.dispatch("location/getDistrictsByProviceId", event.target.value);
			},
			async onSaveNewAgent() {
				
				if(!this.validator(this.editingAgent.name))
				{					
                    this.$store.dispatch('alert/error', this.$t('pages.agent.agentNameisInvalid'));					
					return;
				}

				if (this.ownerModel)
					this.editingAgent.ownerId = this.ownerModel.id;
				else
					this.editingAgent.ownerId = 0;
               
				var retVal = await this.$store.dispatch("agent/saveAgent");               
                if (retVal == true) {                   
                    this.onGoBack();
                }
			},
			async onChangeDistrict(event) {				
				await this.$store.dispatch("location/getWardsByDistrictId", event.target.value);
			},            
		},
		mounted() {
			this.$store.dispatch("location/getAllProvinces");           
            this.$store.dispatch("account/getAgentOwnerUserList", this.$store.state.auth.user.id);
            this.$store.dispatch("agent/initNewAgent");			
		}
	}
</script>